:root {
  --primary-color: #0E67DD;
  --light-color: #fff;
  --main-font: "Poppins",sans-serif;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  background-color: white;
}
html{
  font-size: 16px;
  font-family: "Poppins" ,sans-serif ;
}
body {
  margin:0;
  box-sizing: border-box
}


.radio+.radio {
  margin:10px
}
.radio {
  display:flex
}

.image-gallery-image {
  width: 100%;
  height: 450px;
}

.image-gallery-image .image-gallery-image-fullscreen{
 width:100%;
 height:calc(100vh - 150px)
}
.image-gallery-image button.image-gallery-icon.image-gallery-fullscreen-button {
  display: none;
}

.image-gallery-image-fullscreen.image-gallery-image button.image-gallery-icon.image-gallery-fullscreen-button {
  display:block
}
.image-gallery-slide .image-gallery-description {
  position:relative !important;
  bottom:0 !important;
}

.image-gallery-index {
  right:auto;
  z-index:0 !important
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover {
  border: 2px solid black !important
}

.fui-dropzone-root {
  min-height: 100px;  
  font-size: 1rem;
}

#demo-multiple-chip-label {
  line-height: 2em;
}